




import { Navigation } from "@/mixins/navigation";
import Component, { mixins } from "vue-class-component";

@Component({})
export default class ClientApp extends mixins(Navigation) {}
